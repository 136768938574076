export const front2RowBookSeats = {
	NN1: false,
	NN2: false,
	NN3: false,
	NN4: false,
	NN5: false,
	NN6: false,
	NN7: false,
	NN8: false,
	NN9: false,
	NN10: false,
	NN11: false,
	NN12: false,
	NN13: false,
	NN14: false,
	NN15: false,
	NN16: false,
	NN17: false,
	NN18: false,
	NN19: false,
	NN20: false,

	MM1: false,
	MM2: false,
	MM3: false,
	MM4: false,
	MM5: false,
	MM6: false,
	MM7: false,
	MM8: false,
	MM9: false,
	MM10: false,
	MM11: false,
	MM12: false,
	MM13: false,
	MM14: false,
	MM15: false,
	MM16: false,
	MM17: false,
	MM18: false,
	MM19: false,
	MM20: false,

	LL1: false,
	LL2: false,
	LL3: false,
	LL4: false,
	LL5: false,
	LL6: false,
	LL7: false,
	LL8: false,
	LL9: false,
	LL10: false,
	LL11: false,
	LL12: false,
	LL13: false,
	LL14: false,
	LL15: false,
	LL16: false,
	LL17: false,
	LL18: false,
	LL19: false,
	LL20: false,

	KK1: false,
	KK2: false,
	KK3: false,
	KK4: false,
	KK5: false,
	KK6: false,
	KK7: false,
	KK8: false,
	KK9: false,
	KK10: false,
	KK11: false,
	KK12: false,
	KK13: false,
	KK14: false,
	KK15: false,
	KK16: false,
	KK17: false,
	KK18: false,
	KK19: false,
	KK20: false,
	KK21: false,
	KK22: false,
	KK23: false,
	KK24: false,
	KK25: false,
	KK26: false,
	KK27: false,
	KK28: false,

	JJ1: false,
	JJ2: false,
	JJ3: false,
	JJ4: false,
	JJ5: false,
	JJ6: false,
	JJ7: false,
	JJ8: false,
	JJ9: false,
	JJ10: false,
	JJ11: false,
	JJ12: false,
	JJ13: false,
	JJ14: false,
	JJ15: false,
	JJ16: false,
	JJ17: false,
	JJ18: false,
	JJ19: false,
	JJ20: false,
	JJ21: false,
	JJ22: false,
	JJ23: false,
	JJ24: false,
	JJ25: false,
	JJ26: false,
	JJ27: false,
	JJ28: false,

	II1: false,
	II2: false,
	II3: false,
	II4: false,
	II5: false,
	II6: false,
	II7: false,
	II8: false,
	II9: false,
	II10: false,
	II11: false,
	II12: false,
	II13: false,
	II14: false,
	II15: false,
	II16: false,
	II17: false,
	II18: false,
	II19: false,
	II20: false,
	II21: false,
	II22: false,
	II23: false,
	II24: false,
	II25: false,
	II26: false,
	II27: false,
	II28: false,

	HH1: false,
	HH2: false,
	HH3: false,
	HH4: false,
	HH5: false,
	HH6: false,
	HH7: false,
	HH8: false,
	HH9: false,
	HH10: false,
	HH11: false,
	HH12: false,
	HH13: false,
	HH14: false,
	HH15: false,
	HH16: false,
	HH17: false,
	HH18: false,
	HH19: false,
	HH20: false,
	HH21: false,
	HH22: false,
	HH23: false,
	HH24: false,
	HH25: false,
	HH26: false,
	HH27: false,
	HH28: false,

	GG1: false,
	GG2: false,
	GG3: false,
	GG4: false,
	GG5: false,
	GG6: false,
	GG7: false,
	GG8: false,
	GG9: false,
	GG10: false,
	GG11: false,
	GG12: false,
	GG13: false,
	GG14: false,
	GG15: false,
	GG16: false,
	GG17: false,
	GG18: false,
	GG19: false,
	GG20: false,
	GG21: false,
	GG22: false,
	GG23: false,
	GG24: false,
	GG25: false,
	GG26: false,
	GG27: false,
	GG28: false,

	FF1: false,
	FF2: false,
	FF3: false,
	FF4: false,
	FF5: false,
	FF6: false,
	FF7: false,
	FF8: false,
	FF9: false,
	FF10: false,
	FF11: false,
	FF12: false,
	FF13: false,
	FF14: false,
	FF15: false,
	FF16: false,
	FF17: false,
	FF18: false,
	FF19: false,
	FF20: false,
	FF21: false,
	FF22: false,
	FF23: false,
	FF24: false,
	FF25: false,
	FF26: false,
	FF27: false,
	FF28: false,

	EE1: false,
	EE2: false,
	EE3: false,
	EE4: false,
	EE5: false,
	EE6: false,
	EE7: false,
	EE8: false,
	EE9: false,
	EE10: false,
	EE11: false,
	EE12: false,
	EE13: false,
	EE14: false,
	EE15: false,
	EE16: false,
	EE17: false,
	EE18: false,
	EE19: false,
	EE20: false,
	EE21: false,
	EE22: false,
	EE23: false,
	EE24: false,
	EE25: false,
	EE26: false,
	EE27: false,
	EE28: false,

	DD1: false,
	DD2: false,
	DD3: false,
	DD4: false,
	DD5: false,
	DD6: false,
	DD7: false,
	DD8: false,
	DD9: false,
	DD10: false,
	DD11: false,
	DD12: false,
	DD13: false,
	DD14: false,
	DD15: false,
	DD16: false,
	DD17: false,
	DD18: false,
	DD19: false,
	DD20: false,
	DD21: false,
	DD22: false,
	DD23: false,
	DD24: false,
	DD25: false,
	DD26: false,
	DD27: false,
	DD28: false,

	CC1: false,
	CC2: false,
	CC3: false,
	CC4: false,
	CC5: false,
	CC6: false,
	CC7: false,
	CC8: false,
	CC9: false,
	CC10: false,
	CC11: false,
	CC12: false,
	CC13: false,
	CC14: false,
	CC15: false,
	CC16: false,
	CC17: false,
	CC18: false,
	CC19: false,
	CC20: false,
	CC21: false,
	CC22: false,
	CC23: false,
	CC24: false,
	CC25: false,
	CC26: false,
	CC27: false,
	CC28: false,

	BB1: false,
	BB2: false,
	BB3: false,
	BB4: false,
	BB5: false,
	BB6: false,
	BB7: false,
	BB8: false,
	BB9: false,
	BB10: false,
	BB11: false,
	BB12: false,
	BB13: false,
	BB14: false,
	BB15: false,
	BB16: false,
	BB17: false,
	BB18: false,
	BB19: false,
	BB20: false,
	BB21: false,
	BB22: false,
	BB23: false,
	BB24: false,
	BB25: false,
	BB26: false,
	BB27: false,
	BB28: false,

	AA1: false,
	AA2: false,
	AA3: false,
	AA4: false,
	AA5: false,
	AA6: false,
	AA7: false,
	AA8: false,
	AA9: false,
	AA10: false,
	AA11: false,
	AA12: false,
	AA13: false,
	AA14: false,
	AA15: false,
	AA16: false,
	AA17: false,
	AA18: false,
	AA19: false,
	AA20: false,
	AA21: false,
	AA22: false,
	AA23: false,
	AA24: false,
	AA25: false,
	AA26: false,
	AA27: false,
	AA28: false,

	// GROUND FLOOR BACK SIDE
	Z1: false,
	Z2: false,
	Z3: false,
	Z4: false,
	Z5: false,
	Z6: false,
	Z7: false,
	Z8: false,
	Z9: false,
	Z10: false,
	Z11: false,
	Z12: false,
	Z13: false,
	Z14: false,
	Z15: false,
	Z16: false,
	Z17: false,
	Z18: false,
	Z19: false,
	Z20: false,
	Z21: false,
	Z22: false,
	Z23: false,
	Z24: false,

	Y1: false,
	Y2: false,
	Y3: false,
	Y4: false,
	Y5: false,
	Y6: false,
	Y7: false,
	Y8: false,
	Y9: false,
	Y10: false,
	Y11: false,
	Y12: false,
	Y13: false,
	Y14: false,
	Y15: false,
	Y16: false,
	Y17: false,
	Y18: false,
	Y19: false,
	Y20: false,
	Y21: false,
	Y22: false,
	Y23: false,
	Y24: false,

	X1: false,
	X2: false,
	X3: false,
	X4: false,
	X5: false,
	X6: false,
	X7: false,
	X8: false,
	X9: false,
	X10: false,
	X11: false,
	X12: false,
	X13: false,
	X14: false,
	X15: false,
	X16: false,
	X17: false,
	X18: false,
	X19: false,
	X20: false,
	X21: false,
	X22: false,
	X23: false,
	X24: false,

	W1: false,
	W2: false,
	W3: false,
	W4: false,
	W5: false,
	W6: false,
	W7: false,
	W8: false,
	W9: false,
	W10: false,
	W11: false,
	W12: false,
	W13: false,
	W14: false,
	W15: false,
	W16: false,
	W17: false,
	W18: false,
	W19: false,
	W20: false,
	W21: false,
	W22: false,
	W23: false,
	W24: false,

	V1: false,
	V2: false,
	V3: false,
	V4: false,
	V5: false,
	V6: false,
	V7: false,
	V8: false,
	V9: false,
	V10: false,
	V11: false,
	V12: false,
	V13: false,
	V14: false,
	V15: false,
	V16: false,
	V17: false,
	V18: false,
	V19: false,
	V20: false,
	V21: false,
	V22: false,
	V23: false,
	V24: false,

	U1: false,
	U2: false,
	U3: false,
	U4: false,
	U5: false,
	U6: false,
	U7: false,
	U8: false,
	U9: false,
	U10: false,
	U11: false,
	U12: false,
	U13: false,
	U14: false,
	U15: false,
	U16: false,
	U17: false,
	U18: false,
	U19: false,
	U20: false,
	U21: false,
	U22: false,
	U23: false,
	U24: false,

	T1: false,
	T2: false,
	T3: false,
	T4: false,
	T5: false,
	T6: false,
	T7: false,
	T8: false,
	T9: false,
	T10: false,
	T11: false,
	T12: false,
	T13: false,
	T14: false,
	T15: false,
	T16: false,
	T17: false,
	T18: false,
	T19: false,
	T20: false,
	T21: false,
	T22: false,
	T23: false,
	T24: false,

	S1: false,
	S2: false,
	S3: false,
	S4: false,
	S5: false,
	S6: false,
	S7: false,
	S8: false,
	S9: false,
	S10: false,
	S11: false,
	S12: false,
	S13: false,
	S14: false,
	S15: false,
	S16: false,
	S17: false,
	S18: false,
	S19: false,
	S20: false,
	S21: false,
	S22: false,
	S23: false,
	S24: false,

	R1: false,
	R2: false,
	R3: false,
	R4: false,
	R5: false,
	R6: false,
	R7: false,
	R8: false,
	R9: false,
	R10: false,
	R11: false,
	R12: false,
	R13: false,
	R14: false,
	R15: false,
	R16: false,
	R17: false,
	R18: false,
	R19: false,
	R20: false,
	R21: false,
	R22: false,
	R23: false,
	R24: false,

	Q1: false,
	Q2: false,
	Q3: false,
	Q4: false,
	Q5: false,
	Q6: false,
	Q7: false,
	Q8: false,
	Q9: false,
	Q10: false,
	Q11: false,
	Q12: false,
	Q13: false,
	Q14: false,
	Q15: false,
	Q16: false,
	Q17: false,
	Q18: false,
	Q19: false,
	Q20: false,
	Q21: false,
	Q22: false,
	Q23: false,
	Q24: false,

	P1: false,
	P2: false,
	P3: false,
	P4: false,
	P5: false,
	P6: false,
	P7: false,
	P8: false,
	P9: false,
	P10: false,
	P11: false,
	P12: false,
	P13: false,
	P14: false,
	P15: false,
	P16: false,
	P17: false,
	P18: false,
	P19: false,
	P20: false,
	P21: false,
	P22: false,
	P23: false,
	P24: false,

	O1: false,
	O2: false,
	O3: false,
	O4: false,
	O5: false,
	O6: false,
	O7: false,
	O8: false,
	O9: false,
	O10: false,
	O11: false,
	O12: false,
	O13: false,
	O14: false,
	O15: false,
	O16: false,
	O17: false,
	O18: false,
	O19: false,
	O20: false,
	O21: false,
	O22: false,
	O23: false,
	O24: false,

	N1: false,
	N2: false,
	N3: false,
	N4: false,
	N5: false,
	N6: false,
	N7: false,
	N8: false,
	N9: false,
	N10: false,
	N11: false,
	N12: false,
	N13: false,
	N14: false,
	N15: false,
	N16: false,
	N17: false,
	N18: false,
	N19: false,
	N20: false,
	N21: false,
	N22: false,
	N23: false,
	N24: false,

	M1: false,
	M2: false,
	M3: false,
	M4: false,
	M5: false,
	M6: false,
	M7: false,
	M8: false,
	M9: false,
	M10: false,
	M11: false,
	M12: false,
	M13: false,
	M14: false,
	M15: false,
	M16: false,
	M17: false,
	M18: false,
	M19: false,
	M20: false,
	M21: false,
	M22: false,
	M23: false,
	M24: false,

	// GROUND FLOOR FRONT SIDE

	L1: false,
	L2: false,
	L3: false,
	L4: false,
	L5: false,
	L6: false,
	L7: false,
	L8: false,
	L9: false,
	L10: false,
	L11: false,
	L12: false,
	L13: false,
	L14: false,
	L15: false,
	L16: false,
	L17: false,
	L18: false,
	L19: false,
	L20: false,
	L21: false,
	L22: false,
	L23: false,
	L24: false,
	L25: false,
	L26: false,
	L27: false,
	L28: false,

	K1: false,
	K2: false,
	K3: false,
	K4: false,
	K5: false,
	K6: false,
	K7: false,
	K8: false,
	K9: false,
	K10: false,
	K11: false,
	K12: false,
	K13: false,
	K14: false,
	K15: false,
	K16: false,
	K17: false,
	K18: false,
	K19: false,
	K20: false,
	K21: false,
	K22: false,
	K23: false,
	K24: false,
	K25: false,
	K26: false,
	K27: false,
	K28: false,

	J1: false,
	J2: false,
	J3: false,
	J4: false,
	J5: false,
	J6: false,
	J7: false,
	J8: false,
	J9: false,
	J10: false,
	J11: false,
	J12: false,
	J13: false,
	J14: false,
	J15: false,
	J16: false,
	J17: false,
	J18: false,
	J19: false,
	J20: false,
	J21: false,
	J22: false,
	J23: false,
	J24: false,
	J25: false,
	J26: false,
	J27: false,
	J28: false,

	I1: false,
	I2: false,
	I3: false,
	I4: false,
	I5: false,
	I6: false,
	I7: false,
	I8: false,
	I9: false,
	I10: false,
	I11: false,
	I12: false,
	I13: false,
	I14: false,
	I15: false,
	I16: false,
	I17: false,
	I18: false,
	I19: false,
	I20: false,
	I21: false,
	I22: false,
	I23: false,
	I24: false,
	I25: false,
	I26: false,
	I27: false,
	I28: false,

	H1: false,
	H2: false,
	H3: false,
	H4: false,
	H5: false,
	H6: false,
	H7: false,
	H8: false,
	H9: false,
	H10: false,
	H11: false,
	H12: false,
	H13: false,
	H14: false,
	H15: false,
	H16: false,
	H17: false,
	H18: false,
	H19: false,
	H20: false,
	H21: false,
	H22: false,
	H23: false,
	H24: false,
	H25: false,
	H26: false,
	H27: false,
	H28: false,

	G1: false,
	G2: false,
	G3: false,
	G4: false,
	G5: false,
	G6: false,
	G7: false,
	G8: false,
	G9: false,
	G10: false,
	G11: false,
	G12: false,
	G13: false,
	G14: false,
	G15: false,
	G16: false,
	G17: false,
	G18: false,
	G19: false,
	G20: false,
	G21: false,
	G22: false,
	G23: false,
	G24: false,
	G25: false,
	G26: false,
	G27: false,
	G28: false,

	F1: false,
	F2: false,
	F3: false,
	F4: false,
	F5: false,
	F6: false,
	F7: false,
	F8: false,
	F9: false,
	F10: false,
	F11: false,
	F12: false,
	F13: false,
	F14: false,
	F15: false,
	F16: false,
	F17: false,
	F18: false,
	F19: false,
	F20: false,
	F21: false,
	F22: false,
	F23: false,
	F24: false,
	F25: false,
	F26: false,
	F27: false,
	F28: false,

	E1: false,
	E2: false,
	E3: false,
	E4: false,
	E5: false,
	E6: false,
	E7: false,
	E8: false,
	E9: false,
	E10: false,
	E11: false,
	E12: false,
	E13: false,
	E14: false,
	E15: false,
	E16: false,
	E17: false,
	E18: false,
	E19: false,
	E20: false,
	E21: false,
	E22: false,
	E23: false,
	E24: false,
	E25: false,
	E26: false,
	E27: false,
	E28: false,

	D1: false,
	D2: false,
	D3: false,
	D4: false,
	D5: false,
	D6: false,
	D7: false,
	D8: false,
	D9: false,
	D10: false,
	D11: false,
	D12: false,
	D13: false,
	D14: false,
	D15: false,
	D16: false,
	D17: false,
	D18: false,
	D19: false,
	D20: false,
	D21: false,
	D22: false,
	D23: false,
	D24: false,
	D25: false,
	D26: false,
	D27: false,
	D28: false,

	C1: false,
	C2: false,
	C3: false,
	C4: false,
	C5: false,
	C6: false,
	C7: false,
	C8: false,
	C9: false,
	C10: false,
	C11: false,
	C12: false,
	C13: false,
	C14: false,
	C15: false,
	C16: false,
	C17: false,
	C18: false,
	C19: false,
	C20: false,
	C21: false,
	C22: false,
	C23: false,
	C24: false,
	C25: false,
	C26: false,
	C27: false,
	C28: false,

	B1: true,
	B2: true,
	B3: true,
	B4: true,
	B5: true,
	B6: true,
	B7: true,
	B8: true,
	B9: true,
	B10: true,
	B11: true,
	B12: true,
	B13: true,
	B14: true,
	B15: true,
	B16: true,
	B17: true,
	B18: true,
	B19: true,
	B20: true,
	B21: true,
	B22: true,
	B23: true,
	B24: true,
	B25: true,
	B26: true,
	B27: true,
	B28: true,

	A1: true,
	A2: true,
	A3: true,
	A4: true,
	A5: true,
	A6: true,
	A7: true,
	A8: true,
	A9: true,
	A10: true,
	A11: true,
	A12: true,
	A13: true,
	A14: true,
	A15: true,
	A16: true,
	A17: true,
	A18: true,
	A19: true,
	A20: true,
	A21: true,
	A22: true,
	A23: true,
	A24: true,
	A25: true,
	A26: true,
	A27: true,
	A28: true,
};





// export const tedXSeats = {
// 	NN1: true,
// 	NN2: true,
// 	NN3: true,
// 	NN4: true,
// 	NN5: true,
// 	NN6: true,
// 	NN7: true,
// 	NN8: true,
// 	NN9: true,
// 	NN10: true,
// 	NN11: true,
// 	NN12: true,
// 	NN13: true,
// 	NN14: true,
// 	NN15: true,
// 	NN16: true,
// 	NN17: true,
// 	NN18: true,
// 	NN19: true,
// 	NN20: true,

// 	MM1: false,
// 	MM2: false,
// 	MM3: false,
// 	MM4: false,
// 	MM5: false,
// 	MM6: false,
// 	MM7: false,
// 	MM8: false,
// 	MM9: false,
// 	MM10: false,
// 	MM11: false,
// 	MM12: false,
// 	MM13: false,
// 	MM14: false,
// 	MM15: false,
// 	MM16: false,
// 	MM17: false,
// 	MM18: false,
// 	MM19: false,
// 	MM20: false,

// 	LL1: false,
// 	LL2: false,
// 	LL3: false,
// 	LL4: false,
// 	LL5: false,
// 	LL6: false,
// 	LL7: false,
// 	LL8: false,
// 	LL9: false,
// 	LL10: false,
// 	LL11: false,
// 	LL12: false,
// 	LL13: false,
// 	LL14: false,
// 	LL15: false,
// 	LL16: false,
// 	LL17: false,
// 	LL18: false,
// 	LL19: false,
// 	LL20: false,

// 	KK1: true,
// 	KK2: true,
// 	KK3: true,
// 	KK4: true,
// 	KK5: true,
// 	KK6: true,
// 	KK7: true,
// 	KK8: true,
// 	KK9: true,
// 	KK10: true,
// 	KK11: true,
// 	KK12: true,
// 	KK13: true,
// 	KK14: true,
// 	KK15: true,
// 	KK16: true,
// 	KK17: true,
// 	KK18: true,
// 	KK19: true,
// 	KK20: true,
// 	KK21: true,
// 	KK22: true,
// 	KK23: true,
// 	KK24: true,
// 	KK25: true,
// 	KK26: true,
// 	KK27: true,
// 	KK28: true,

// 	JJ1: false,
// 	JJ2: false,
// 	JJ3: false,
// 	JJ4: false,
// 	JJ5: false,
// 	JJ6: false,
// 	JJ7: false,
// 	JJ8: false,
// 	JJ9: false,
// 	JJ10: false,
// 	JJ11: false,
// 	JJ12: false,
// 	JJ13: false,
// 	JJ14: false,
// 	JJ15: false,
// 	JJ16: false,
// 	JJ17: false,
// 	JJ18: false,
// 	JJ19: false,
// 	JJ20: false,
// 	JJ21: false,
// 	JJ22: false,
// 	JJ23: false,
// 	JJ24: false,
// 	JJ25: false,
// 	JJ26: false,
// 	JJ27: false,
// 	JJ28: false,

// 	II1: false,
// 	II2: false,
// 	II3: false,
// 	II4: false,
// 	II5: false,
// 	II6: false,
// 	II7: false,
// 	II8: false,
// 	II9: false,
// 	II10: false,
// 	II11: false,
// 	II12: false,
// 	II13: false,
// 	II14: false,
// 	II15: false,
// 	II16: false,
// 	II17: false,
// 	II18: false,
// 	II19: false,
// 	II20: false,
// 	II21: false,
// 	II22: false,
// 	II23: false,
// 	II24: false,
// 	II25: false,
// 	II26: false,
// 	II27: false,
// 	II28: false,

// 	HH1: true,
// 	HH2: true,
// 	HH3: true,
// 	HH4: true,
// 	HH5: true,
// 	HH6: true,
// 	HH7: true,
// 	HH8: true,
// 	HH9: true,
// 	HH10: true,
// 	HH11: true,
// 	HH12: true,
// 	HH13: true,
// 	HH14: true,
// 	HH15: true,
// 	HH16: true,
// 	HH17: true,
// 	HH18: true,
// 	HH19: true,
// 	HH20: true,
// 	HH21: true,
// 	HH22: true,
// 	HH23: true,
// 	HH24: true,
// 	HH25: true,
// 	HH26: true,
// 	HH27: true,
// 	HH28: true,

// 	GG1: false,
// 	GG2: false,
// 	GG3: false,
// 	GG4: false,
// 	GG5: false,
// 	GG6: false,
// 	GG7: false,
// 	GG8: false,
// 	GG9: false,
// 	GG10: false,
// 	GG11: false,
// 	GG12: false,
// 	GG13: false,
// 	GG14: false,
// 	GG15: false,
// 	GG16: false,
// 	GG17: false,
// 	GG18: false,
// 	GG19: false,
// 	GG20: false,
// 	GG21: false,
// 	GG22: false,
// 	GG23: false,
// 	GG24: false,
// 	GG25: false,
// 	GG26: false,
// 	GG27: false,
// 	GG28: false,

// 	FF1: false,
// 	FF2: false,
// 	FF3: false,
// 	FF4: false,
// 	FF5: false,
// 	FF6: false,
// 	FF7: false,
// 	FF8: false,
// 	FF9: false,
// 	FF10: false,
// 	FF11: false,
// 	FF12: false,
// 	FF13: false,
// 	FF14: false,
// 	FF15: false,
// 	FF16: false,
// 	FF17: false,
// 	FF18: false,
// 	FF19: false,
// 	FF20: false,
// 	FF21: false,
// 	FF22: false,
// 	FF23: false,
// 	FF24: false,
// 	FF25: false,
// 	FF26: false,
// 	FF27: false,
// 	FF28: false,

// 	EE1: true,
// 	EE2: true,
// 	EE3: true,
// 	EE4: true,
// 	EE5: true,
// 	EE6: true,
// 	EE7: true,
// 	EE8: true,
// 	EE9: true,
// 	EE10: true,
// 	EE11: true,
// 	EE12: true,
// 	EE13: true,
// 	EE14: true,
// 	EE15: true,
// 	EE16: true,
// 	EE17: true,
// 	EE18: true,
// 	EE19: true,
// 	EE20: true,
// 	EE21: true,
// 	EE22: true,
// 	EE23: true,
// 	EE24: true,
// 	EE25: true,
// 	EE26: true,
// 	EE27: true,
// 	EE28: true,

// 	DD1: false,
// 	DD2: false,
// 	DD3: false,
// 	DD4: false,
// 	DD5: false,
// 	DD6: false,
// 	DD7: false,
// 	DD8: false,
// 	DD9: false,
// 	DD10: false,
// 	DD11: false,
// 	DD12: false,
// 	DD13: false,
// 	DD14: false,
// 	DD15: false,
// 	DD16: false,
// 	DD17: false,
// 	DD18: false,
// 	DD19: false,
// 	DD20: false,
// 	DD21: false,
// 	DD22: false,
// 	DD23: false,
// 	DD24: false,
// 	DD25: false,
// 	DD26: false,
// 	DD27: false,
// 	DD28: false,

// 	CC1: false,
// 	CC2: false,
// 	CC3: false,
// 	CC4: false,
// 	CC5: false,
// 	CC6: false,
// 	CC7: false,
// 	CC8: false,
// 	CC9: false,
// 	CC10: false,
// 	CC11: false,
// 	CC12: false,
// 	CC13: false,
// 	CC14: false,
// 	CC15: false,
// 	CC16: false,
// 	CC17: false,
// 	CC18: false,
// 	CC19: false,
// 	CC20: false,
// 	CC21: false,
// 	CC22: false,
// 	CC23: false,
// 	CC24: false,
// 	CC25: false,
// 	CC26: false,
// 	CC27: false,
// 	CC28: false,

// 	BB1: true,
// 	BB2: true,
// 	BB3: true,
// 	BB4: true,
// 	BB5: true,
// 	BB6: true,
// 	BB7: true,
// 	BB8: true,
// 	BB9: true,
// 	BB10: true,
// 	BB11: true,
// 	BB12: true,
// 	BB13: true,
// 	BB14: true,
// 	BB15: true,
// 	BB16: true,
// 	BB17: true,
// 	BB18: true,
// 	BB19: true,
// 	BB20: true,
// 	BB21: true,
// 	BB22: true,
// 	BB23: true,
// 	BB24: true,
// 	BB25: true,
// 	BB26: true,
// 	BB27: true,
// 	BB28: true,

// 	AA1: false,
// 	AA2: false,
// 	AA3: false,
// 	AA4: false,
// 	AA5: false,
// 	AA6: false,
// 	AA7: false,
// 	AA8: false,
// 	AA9: false,
// 	AA10: false,
// 	AA11: false,
// 	AA12: false,
// 	AA13: false,
// 	AA14: false,
// 	AA15: false,
// 	AA16: false,
// 	AA17: false,
// 	AA18: false,
// 	AA19: false,
// 	AA20: false,
// 	AA21: false,
// 	AA22: false,
// 	AA23: false,
// 	AA24: false,
// 	AA25: false,
// 	AA26: false,
// 	AA27: false,
// 	AA28: false,

// 	// GROUND FLOOR BACK SIDE
// 	Z1: true,
// 	Z2: true,
// 	Z3: true,
// 	Z4: true,
// 	Z5: true,
// 	Z6: true,
// 	Z7: true,
// 	Z8: true,
// 	Z9: true,
// 	Z10: true,
// 	Z11: true,
// 	Z12: true,
// 	Z13: true,
// 	Z14: true,
// 	Z15: true,
// 	Z16: true,
// 	Z17: true,
// 	Z18: true,
// 	Z19: true,
// 	Z20: true,
// 	Z21: true,
// 	Z22: true,
// 	Z23: true,
// 	Z24: true,

// 	Y1: false,
// 	Y2: false,
// 	Y3: false,
// 	Y4: false,
// 	Y5: false,
// 	Y6: false,
// 	Y7: false,
// 	Y8: false,
// 	Y9: false,
// 	Y10: false,
// 	Y11: false,
// 	Y12: false,
// 	Y13: false,
// 	Y14: false,
// 	Y15: false,
// 	Y16: false,
// 	Y17: false,
// 	Y18: false,
// 	Y19: false,
// 	Y20: false,
// 	Y21: false,
// 	Y22: false,
// 	Y23: false,
// 	Y24: false,

// 	X1: false,
// 	X2: false,
// 	X3: false,
// 	X4: false,
// 	X5: false,
// 	X6: false,
// 	X7: false,
// 	X8: false,
// 	X9: false,
// 	X10: false,
// 	X11: false,
// 	X12: false,
// 	X13: false,
// 	X14: false,
// 	X15: false,
// 	X16: false,
// 	X17: false,
// 	X18: false,
// 	X19: false,
// 	X20: false,
// 	X21: false,
// 	X22: false,
// 	X23: false,
// 	X24: false,

// 	W1: true,
// 	W2: true,
// 	W3: true,
// 	W4: true,
// 	W5: true,
// 	W6: true,
// 	W7: true,
// 	W8: true,
// 	W9: true,
// 	W10: true,
// 	W11: true,
// 	W12: true,
// 	W13: true,
// 	W14: true,
// 	W15: true,
// 	W16: true,
// 	W17: true,
// 	W18: true,
// 	W19: true,
// 	W20: true,
// 	W21: true,
// 	W22: true,
// 	W23: true,
// 	W24: true,

// 	V1: false,
// 	V2: false,
// 	V3: false,
// 	V4: false,
// 	V5: false,
// 	V6: false,
// 	V7: false,
// 	V8: false,
// 	V9: false,
// 	V10: false,
// 	V11: false,
// 	V12: false,
// 	V13: false,
// 	V14: false,
// 	V15: false,
// 	V16: false,
// 	V17: false,
// 	V18: false,
// 	V19: false,
// 	V20: false,
// 	V21: false,
// 	V22: false,
// 	V23: false,
// 	V24: false,

// 	U1: false,
// 	U2: false,
// 	U3: false,
// 	U4: false,
// 	U5: false,
// 	U6: false,
// 	U7: false,
// 	U8: false,
// 	U9: false,
// 	U10: false,
// 	U11: false,
// 	U12: false,
// 	U13: false,
// 	U14: false,
// 	U15: false,
// 	U16: false,
// 	U17: false,
// 	U18: false,
// 	U19: false,
// 	U20: false,
// 	U21: false,
// 	U22: false,
// 	U23: false,
// 	U24: false,

// 	T1: true,
// 	T2: true,
// 	T3: true,
// 	T4: true,
// 	T5: true,
// 	T6: true,
// 	T7: true,
// 	T8: true,
// 	T9: true,
// 	T10: true,
// 	T11: true,
// 	T12: true,
// 	T13: true,
// 	T14: true,
// 	T15: true,
// 	T16: true,
// 	T17: true,
// 	T18: true,
// 	T19: true,
// 	T20: true,
// 	T21: true,
// 	T22: true,
// 	T23: true,
// 	T24: true,

// 	S1: false,
// 	S2: false,
// 	S3: false,
// 	S4: false,
// 	S5: false,
// 	S6: false,
// 	S7: false,
// 	S8: false,
// 	S9: false,
// 	S10: false,
// 	S11: false,
// 	S12: false,
// 	S13: false,
// 	S14: false,
// 	S15: false,
// 	S16: false,
// 	S17: false,
// 	S18: false,
// 	S19: false,
// 	S20: false,
// 	S21: false,
// 	S22: false,
// 	S23: false,
// 	S24: false,

// 	R1: false,
// 	R2: false,
// 	R3: false,
// 	R4: false,
// 	R5: false,
// 	R6: false,
// 	R7: false,
// 	R8: false,
// 	R9: false,
// 	R10: false,
// 	R11: false,
// 	R12: false,
// 	R13: false,
// 	R14: false,
// 	R15: false,
// 	R16: false,
// 	R17: false,
// 	R18: false,
// 	R19: false,
// 	R20: false,
// 	R21: false,
// 	R22: false,
// 	R23: false,
// 	R24: false,

// 	Q1: true,
// 	Q2: true,
// 	Q3: true,
// 	Q4: true,
// 	Q5: true,
// 	Q6: true,
// 	Q7: true,
// 	Q8: true,
// 	Q9: true,
// 	Q10: true,
// 	Q11: true,
// 	Q12: true,
// 	Q13: true,
// 	Q14: true,
// 	Q15: true,
// 	Q16: true,
// 	Q17: true,
// 	Q18: true,
// 	Q19: true,
// 	Q20: true,
// 	Q21: true,
// 	Q22: true,
// 	Q23: true,
// 	Q24: true,

// 	P1: false,
// 	P2: false,
// 	P3: false,
// 	P4: false,
// 	P5: false,
// 	P6: false,
// 	P7: false,
// 	P8: false,
// 	P9: false,
// 	P10: false,
// 	P11: false,
// 	P12: false,
// 	P13: false,
// 	P14: false,
// 	P15: false,
// 	P16: false,
// 	P17: false,
// 	P18: false,
// 	P19: false,
// 	P20: false,
// 	P21: false,
// 	P22: false,
// 	P23: false,
// 	P24: false,

// 	O1: false,
// 	O2: false,
// 	O3: false,
// 	O4: false,
// 	O5: false,
// 	O6: false,
// 	O7: false,
// 	O8: false,
// 	O9: false,
// 	O10: false,
// 	O11: false,
// 	O12: false,
// 	O13: false,
// 	O14: false,
// 	O15: false,
// 	O16: false,
// 	O17: false,
// 	O18: false,
// 	O19: false,
// 	O20: false,
// 	O21: false,
// 	O22: false,
// 	O23: false,
// 	O24: false,

// 	N1: true,
// 	N2: true,
// 	N3: true,
// 	N4: true,
// 	N5: true,
// 	N6: true,
// 	N7: true,
// 	N8: true,
// 	N9: true,
// 	N10: true,
// 	N11: true,
// 	N12: true,
// 	N13: true,
// 	N14: true,
// 	N15: true,
// 	N16: true,
// 	N17: true,
// 	N18: true,
// 	N19: true,
// 	N20: true,
// 	N21: true,
// 	N22: true,
// 	N23: true,
// 	N24: true,

// 	M1: false,
// 	M2: false,
// 	M3: false,
// 	M4: false,
// 	M5: false,
// 	M6: false,
// 	M7: false,
// 	M8: false,
// 	M9: false,
// 	M10: false,
// 	M11: false,
// 	M12: false,
// 	M13: false,
// 	M14: false,
// 	M15: false,
// 	M16: false,
// 	M17: false,
// 	M18: false,
// 	M19: false,
// 	M20: false,
// 	M21: false,
// 	M22: false,
// 	M23: false,
// 	M24: false,

// 	// GROUND FLOOR FRONT SIDE

// 	L1: true,
// 	L2: true,
// 	L3: true,
// 	L4: true,
// 	L5: true,
// 	L6: true,
// 	L7: true,
// 	L8: true,
// 	L9: true,
// 	L10: true,
// 	L11: true,
// 	L12: true,
// 	L13: true,
// 	L14: true,
// 	L15: true,
// 	L16: true,
// 	L17: true,
// 	L18: true,
// 	L19: true,
// 	L20: true,
// 	L21: true,
// 	L22: true,
// 	L23: true,
// 	L24: true,
// 	L25: true,
// 	L26: true,
// 	L27: true,
// 	L28: true,

// 	K1: false,
// 	K2: false,
// 	K3: false,
// 	K4: false,
// 	K5: false,
// 	K6: false,
// 	K7: false,
// 	K8: false,
// 	K9: false,
// 	K10: false,
// 	K11: false,
// 	K12: false,
// 	K13: false,
// 	K14: false,
// 	K15: false,
// 	K16: false,
// 	K17: false,
// 	K18: false,
// 	K19: false,
// 	K20: false,
// 	K21: false,
// 	K22: false,
// 	K23: false,
// 	K24: false,
// 	K25: false,
// 	K26: false,
// 	K27: false,
// 	K28: false,

// 	J1: true,
// 	J2: true,
// 	J3: true,
// 	J4: true,
// 	J5: true,
// 	J6: true,
// 	J7: true,
// 	J8: true,
// 	J9: true,
// 	J10: true,
// 	J11: true,
// 	J12: true,
// 	J13: true,
// 	J14: true,
// 	J15: true,
// 	J16: true,
// 	J17: true,
// 	J18: true,
// 	J19: true,
// 	J20: true,
// 	J21: true,
// 	J22: true,
// 	J23: true,
// 	J24: true,
// 	J25: true,
// 	J26: true,
// 	J27: true,
// 	J28: true,

// 	I1: false,
// 	I2: false,
// 	I3: false,
// 	I4: false,
// 	I5: false,
// 	I6: false,
// 	I7: false,
// 	I8: false,
// 	I9: false,
// 	I10: false,
// 	I11: false,
// 	I12: false,
// 	I13: false,
// 	I14: false,
// 	I15: false,
// 	I16: false,
// 	I17: false,
// 	I18: false,
// 	I19: false,
// 	I20: false,
// 	I21: false,
// 	I22: false,
// 	I23: false,
// 	I24: false,
// 	I25: false,
// 	I26: false,
// 	I27: false,
// 	I28: false,

// 	H1: false,
// 	H2: false,
// 	H3: false,
// 	H4: false,
// 	H5: false,
// 	H6: false,
// 	H7: false,
// 	H8: false,
// 	H9: false,
// 	H10: false,
// 	H11: false,
// 	H12: false,
// 	H13: false,
// 	H14: false,
// 	H15: false,
// 	H16: false,
// 	H17: false,
// 	H18: false,
// 	H19: false,
// 	H20: false,
// 	H21: false,
// 	H22: false,
// 	H23: false,
// 	H24: false,
// 	H25: false,
// 	H26: false,
// 	H27: false,
// 	H28: false,

// 	G1: true,
// 	G2: true,
// 	G3: true,
// 	G4: true,
// 	G5: true,
// 	G6: true,
// 	G7: true,
// 	G8: true,
// 	G9: true,
// 	G10: true,
// 	G11: true,
// 	G12: true,
// 	G13: true,
// 	G14: true,
// 	G15: true,
// 	G16: true,
// 	G17: true,
// 	G18: true,
// 	G19: true,
// 	G20: true,
// 	G21: true,
// 	G22: true,
// 	G23: true,
// 	G24: true,
// 	G25: true,
// 	G26: true,
// 	G27: true,
// 	G28: true,

// 	F1: false,
// 	F2: false,
// 	F3: false,
// 	F4: false,
// 	F5: false,
// 	F6: false,
// 	F7: false,
// 	F8: false,
// 	F9: false,
// 	F10: false,
// 	F11: false,
// 	F12: false,
// 	F13: false,
// 	F14: false,
// 	F15: false,
// 	F16: false,
// 	F17: false,
// 	F18: false,
// 	F19: false,
// 	F20: false,
// 	F21: false,
// 	F22: false,
// 	F23: false,
// 	F24: false,
// 	F25: false,
// 	F26: false,
// 	F27: false,
// 	F28: false,

// 	E1: true,
// 	E2: true,
// 	E3: true,
// 	E4: true,
// 	E5: true,
// 	E6: true,
// 	E7: true,
// 	E8: true,
// 	E9: true,
// 	E10: true,
// 	E11: true,
// 	E12: true,
// 	E13: true,
// 	E14: true,
// 	E15: true,
// 	E16: true,
// 	E17: true,
// 	E18: true,
// 	E19: true,
// 	E20: true,
// 	E21: true,
// 	E22: true,
// 	E23: true,
// 	E24: true,
// 	E25: true,
// 	E26: true,
// 	E27: true,
// 	E28: true,

// 	D1: true,
// 	D2: true,
// 	D3: true,
// 	D4: true,
// 	D5: true,
// 	D6: true,
// 	D7: true,
// 	D8: true,
// 	D9: true,
// 	D10: true,
// 	D11: true,
// 	D12: true,
// 	D13: true,
// 	D14: true,
// 	D15: true,
// 	D16: true,
// 	D17: true,
// 	D18: true,
// 	D19: true,
// 	D20: true,
// 	D21: true,
// 	D22: true,
// 	D23: true,
// 	D24: true,
// 	D25: true,
// 	D26: true,
// 	D27: true,
// 	D28: true,

// 	C1: true,
// 	C2: true,
// 	C3: true,
// 	C4: true,
// 	C5: true,
// 	C6: true,
// 	C7: true,
// 	C8: true,
// 	C9: true,
// 	C10: true,
// 	C11: true,
// 	C12: true,
// 	C13: true,
// 	C14: true,
// 	C15: true,
// 	C16: true,
// 	C17: true,
// 	C18: true,
// 	C19: true,
// 	C20: true,
// 	C21: true,
// 	C22: true,
// 	C23: true,
// 	C24: true,
// 	C25: true,
// 	C26: true,
// 	C27: true,
// 	C28: true,

// 	B1: true,
// 	B2: true,
// 	B3: true,
// 	B4: true,
// 	B5: true,
// 	B6: true,
// 	B7: true,
// 	B8: true,
// 	B9: true,
// 	B10: true,
// 	B11: true,
// 	B12: true,
// 	B13: true,
// 	B14: true,
// 	B15: true,
// 	B16: true,
// 	B17: true,
// 	B18: true,
// 	B19: true,
// 	B20: true,
// 	B21: true,
// 	B22: true,
// 	B23: true,
// 	B24: true,
// 	B25: true,
// 	B26: true,
// 	B27: true,
// 	B28: true,

// 	A1: true,
// 	A2: true,
// 	A3: true,
// 	A4: true,
// 	A5: true,
// 	A6: true,
// 	A7: true,
// 	A8: true,
// 	A9: true,
// 	A10: true,
// 	A11: true,
// 	A12: true,
// 	A13: true,
// 	A14: true,
// 	A15: true,
// 	A16: true,
// 	A17: true,
// 	A18: true,
// 	A19: true,
// 	A20: true,
// 	A21: true,
// 	A22: true,
// 	A23: true,
// 	A24: true,
// 	A25: true,
// 	A26: true,
// 	A27: true,
// 	A28: true,
// };